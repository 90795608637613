$(function ($) {

	const $moreBtn = $('.more-btn');

	if ( !$moreBtn.length ) return false;

	// const $moreContent = $('.more-content');

	$moreBtn.on('click', function () {
		let index = $(this).attr('data-index');

		$(this).toggleClass('more-btn_active');

		$(`.more-content[data-index="${index}"]`).toggleClass('more-content_active');
	});

});