$(function ($) {
	
	const $lang = $('.lang');
	
	if ( !$lang.length ) return false;
	
	const $btn = $lang.find('.lang__btn');
	const $list = $lang.find('.lang__list');
	
	$btn.on('click', function (event) {
		$list.slideToggle(200);
	});

	$(document).on('click', function (event) {
		const $target = $(event.target);

		if ( !$target.parents().is('.lang') && !$target.is('.lang') ) {
			$list.slideUp(200);
		}
	});

});