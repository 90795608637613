$(function ($) {

	const $info = $('.info');

	if ( !$info.length ) return false;

	const $tab = $info.find('.info__tab');
	const $content = $info.find('.info__content');

	$tab.on('click', function () {
		if ( !$(this).hasClass('info__tab_active') ) {
			let index = $(this).attr('data-index');

			$tab.removeClass('info__tab_active');
			$content.hide();
			$(this).addClass('info__tab_active');
			$info.find(`.info__content[data-index="${index}"]`).fadeIn(200);
		}
	});

});