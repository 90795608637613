$(function ($) {

	const $booking = $('.booking');

	if ( !$booking.length ) return false;

	const $station = $booking.find('.booking__station');

	const $stations = $booking.find('.booking__stations');

	const $stationsInfo = $booking.find('.booking__stations-info');

	const $date = $booking.find('.booking__date');
	const $time = $booking.find('.booking__time');

	const $uci = $booking.find('input[name="uci"]');
	const $rci = $booking.find('input[name="rci"]');
	const $uda = $booking.find('input[name="uda"]');
	const $uti = $booking.find('input[name="uti"]');
	const $rda = $booking.find('input[name="rda"]');
	const $rti = $booking.find('input[name="rti"]');

	const locale = $('html').attr('lang');

	let info = {};

	let map;
	let icon = {
		url: '../../images/icons/placemark.svg',
		scaledSize: new google.maps.Size(30, 30),
		origin: new google.maps.Point(0,0),
		anchor: new google.maps.Point(0, 0)
	};

	$station.on('input', function () {
		search($(this));
	});

	$station.on('click', function (event) {
		const index = $(this).attr('data-index');
		let value = $(this).val();

		if ( value.length > 2 ) {
			search($(this));

			if ( !$(this).hasClass('booking__station_active') ) {
				closeAll();
				$booking.find(`.booking__stations[data-index="${index}"]`).fadeIn(200);
				$(this).addClass('booking__station_active');
			}
		}
		else {
			closeAll();
			$station.removeClass('booking__station_active');
		}
	});

	$(document).on('mouseover', '.booking__stations-item', function () {

		if ( app.getResolution().number > 3 ) {
			let code = $(this).attr('data-code');
			const $bookingStations = $(this).parent().parent().parent();
			const index = $bookingStations.attr('data-index');
			const $stationsInfo = $bookingStations.find('.booking__stations-info');
			const $stationDetails = $bookingStations.find('.booking__station-details');
			let detailsHTML = '';

			let days = {
				'MO': 'Monday',
				'TU': 'Tuesday',
				'WE': 'Wednesday',
				'TH': 'Thursday',
				'FR': 'Friday',
				'SA': 'Saturday',
				'SU': 'Sunday'
			};

			const $stationMap = document.querySelector(`
				.booking__stations[data-index="${index}"]
				.booking__station-map
			`);

			let lat = parseFloat(info[code].LATU);
			let lng = parseFloat(info[code].LOTU);

			let LatLng = { lat: lat, lng: lng };

			detailsHTML += `<div class="booking__station-details-title">${info[code].SNAM}</div>`;
			detailsHTML += `<div class="booking__station-details-info">${info[code].STR}, ${info[code].CITY}, ${info[code].COUNTRY}</div>`;

			detailsHTML += `<table>`;

			if ( info[code].TEL ) {
				detailsHTML += `
					<tr>
						<td>Phone no.:</td>
						<td>${info[code].TEL}</td>
					</tr>
				`;
			}

			if ( info[code].FAX ) {
				detailsHTML += `
					<tr>
						<td>Fax:</td>
						<td>${info[code].FAX}</td>
					</tr>
				`;
			}

			if ( info[code].OPENHOURS['MO-SU'] ) {
				detailsHTML += `
					<tr>
						<td>Daily</td>
						<td>${info[code].OPENHOURS['MO-SU']} hrs</td>
					</tr>
				`;
			}
			else {
				$.each(info[code].OPENHOURS, function (index, value) {
					let item = index.split('-');
					
					if ( item.length > 1 ) {
						detailsHTML += `
							<tr>
								<td>${days[item[0]]} - ${days[item[1]]}</td>
								<td>${info[code].OPENHOURS[item[0] + '-' + item[1]]} hrs</td>
							</tr>
						`;
					}
					else {
						detailsHTML += `
							<tr>
								<td>${days[item[0]]}</td>
								<td>${info[code].OPENHOURS[item[0]]} hrs</td>
							</tr>
						`;
					}
				});
			}

			if ( info[code].OPENHOURS['HO'] ) {
				detailsHTML += `
					<tr>
						<td>On bank holidays</td>
						<td>${info[code].OPENHOURS['HO']} hrs</td>
					</tr>
				`;
			}

			detailsHTML += `</table>`;

			$stationDetails.html(detailsHTML);

			initMap($stationMap, LatLng);

			$('.booking__stations-item').removeClass('booking__stations-item_hover');
			$(this).addClass('booking__stations-item_hover');

			$stationsInfo.fadeIn(200);
		}
	});

	$(document).on('click', '.booking__stations-item', function () {
		const index = $(this).parent().parent().parent().attr('data-index');
		let code = $.trim( $(this).attr('data-code') );
		let name = info[code].SNAM;

		let $input = $booking.find(`.booking__station[data-index="${index}"]`);

		$input.val(name);

		if ( $input.hasClass('booking__station-start') ) {
			$booking.find('.booking__station-end').val(name);
			$uci.val(code);
			$rci.val(code);
		}
		else {
			$rci.val(code);
		}

		closeAll();
	});

	$(document).on('click', '.booking__get-countries', function () {
		countries($(this));
	});

	$(document).on('click', '.booking__countries-item', function () {
		cities($(this));
	});

	$(document).on('click', '.booking__cities-item', function () {
		let adr = $(this).attr('data-adr');
		let index = $(this).parent().attr('data-index');
		let $input = $booking.find(`.booking__station[data-index="${index}"]`);

		$input.val(adr);

		search($input);
	});

	$date.find('.booking__input').datetimepicker({
		format: 'MM/DD/YYYY',
		allowInputToggle: true,
		locale: locale,
		minDate: (new Date).setHours(0, 0, 0, 0),
		useCurrent: false,
		ignoreReadonly: true,
		debug: false,
		widgetPositioning: {
			horizontal: 'left',
			vertical: 'bottom'
		},
		icons: {
			previous: 'booking__icon-prev',
			next: 'booking__icon-next',
		}
	});

	$time.find('.booking__input').datetimepicker({
		format: "HH:mm",
		allowInputToggle: true,
		locale: locale,
		stepping: 30,
		ignoreReadonly: true,
		debug: false,
		widgetPositioning: {
			horizontal: 'right',
			vertical: 'bottom'
		},
		icons: {
			up: 'booking__icon-up',
			down: "booking__icon-down",
		}
	});

	$(document).on('click', function (event) {
		const $target = $(event.target);

		if (
			!$target.is('.booking__station') &&
			!$target.parents().is('.booking__station') &&
			!$target.is('.booking__stations') &&
			!$target.parents().is('.booking__stations')
		) {
			closeAll();
		}
	});

	function search($elem) {
		let index = $elem.attr('data-index');
		let $input = $elem;
		let $stationsContent = $('.booking__stations[data-index="' + index + '"] .booking__stations-content');
		let stationsHTML = '';
		let adr = $elem.val();
		let url = '/search?_=1564669805367&adr=' + adr + '&por=p&tab_identifier=1564663594';

		if ( adr.length > 2 ) {

			$.ajax({
				url: url,
				type: "GET",
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
				},
				success: function(result) {
					let data = JSON.parse(result);

					info = {};

					if (
						!data.rec.station_airport.length &&
						!data.rec.station_railway.length &&
						!data.rec.station_downtown.length &&
						!data.rec.station_nearby.length
					) {
						stationsHTML = `<div class="booking__search-info" data-index="${index}">please check your entry for typing errors or choose a <span class="booking__get-countries">country</span> first.</div>`;
					}
					else {

						if ( data.rec.station_airport.length ) {
							stationsHTML += generateStations('Sixt airport stations', data.rec.station_airport, 'booking__stations-item_airport');
						}

						if ( data.rec.station_downtown.length ) {
							stationsHTML += generateStations('Sixt city stations', data.rec.station_downtown, 'booking__stations-item_city');
						}

						if ( data.rec.station_railway.length ) {
							stationsHTML += generateStations('Sixt train stations', data.rec.station_railway, 'booking__stations-item_train');
						}

						if ( data.rec.station_nearby.length ) {
							stationsHTML += generateStations('Sixt nearby stations', data.rec.station_nearby, '');
						}
					}

					$stationsContent.html(stationsHTML);

					if ( !$input.hasClass('booking__station_active') ) {
						closeAll();
						$booking.find(`.booking__stations[data-index="${index}"]`).fadeIn(200);
						$input.addClass('booking__station_active');
					}
				}
			});
		}
		else {
			if ( $input.hasClass('booking__station_active') ) {
				closeAll();
				$input.removeClass('booking__station_active');
			}
		}
	}

	function countries($elem) {
		let index = $elem.parent().attr('data-index');
		let $input = $booking.find(`.booking__station[data-index="${index}"]`);
		let $stationsContent = $('.booking__stations[data-index="' + index + '"] .booking__stations-content');
		let countriesHTML = '';
		let url = '/search?_=1564751753937&error=true&tab_identifier=1564751621';

		$.ajax({
			url: url,
			type: "GET",
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			},
			success: function(result) {
				let data = JSON.parse(result);

				countriesHTML += '<div class="booking__countries">';
				countriesHTML += '<div class="booking__countries-title">Countries</div>';
				countriesHTML += `<ul class="booking__countries-items" data-index="${index}">`;

				$.each(data.rec.country, function (index, value) {
					countriesHTML += `<li class="booking__countries-item" data-liso="${value.LISO}">${value.COUNTRY}</li>`;
				});

				countriesHTML += '</ul>';
				countriesHTML += '</div>';

				$stationsContent.html(countriesHTML);
			}
		});
	}

	function cities($elem) {
		let index = $elem.parent().attr('data-index');
		let liso = $elem.attr('data-liso');
		let $input = $booking.find(`.booking__station[data-index="${index}"]`);
		let $stationsContent = $('.booking__stations[data-index="' + index + '"] .booking__stations-content');
		let citiesHTML = '';
		let url = `/search?_=1564752133635&liso=${liso}&tab_identifier=1564751621`;

		$.ajax({
			url: url,
			type: "GET",
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			},
			success: function(result) {
				let data = JSON.parse(result);

				citiesHTML += '<div class="booking__cities">';
				citiesHTML += '<div class="booking__cities-title">Cities</div>';
				citiesHTML += `<ul class="booking__cities-items" data-index="${index}">`;

				$.each(data.rec.town_other, function (index, value) {
					citiesHTML += `<li class="booking__cities-item" data-adr="${value.TOWN}">${value.TOWN}</li>`;
				});

				citiesHTML += '</ul>';
				citiesHTML += '</div>';

				$stationsContent.html(citiesHTML);
			}
		});
	}

	function generateStations(title, items, modifier) {
		let html = '';

		html += '<div class="booking__stations-title">' + title + '</div>';

		html += '<ul class="booking__stations-list">';

		$.each(items, function (index, value) {
			info[value.CIT] = value;
			html += '<li class="booking__stations-item ' + modifier + '" data-code="' + value.CIT + '">' + value.SNAM + ' (' + value.LISO + ')</li>';
		});

		html += '</ul>';

		return html;
	}

	function closeAll() {
		$station.removeClass('booking__station_active');
		$stations.fadeOut(200);
		$stationsInfo.hide(200);
		$('.booking__stations-item').removeClass('booking__stations-item_hover');
	}

	function initMap(element, LatLng) {
		map = new google.maps.Map(element, {
			center: LatLng,
			zoom: 16
		});

		new google.maps.Marker({
			map: map,
			position: LatLng,
			icon: icon
		});
	}

});