$(function ($) {

	const $heroSlider = $('.hero-slider');

	if ( !$heroSlider.length ) return false;

	let slider = new Swiper('.hero-slider__container', {
		navigation: {
			nextEl: '.hero-slider__slider-btn_next',
			prevEl: '.hero-slider__slider-btn_prev',
		},
		effect: 'fade',
		fadeEffect: {
			crossFade: true,
		},
		speed: 500
	});

});