$(function ($) {

	const $location = $('.location');

	if ( !$location.length ) return false;

	let map;
	let icon = {
		url: '../../images/icons/placemark.svg',
		scaledSize: new google.maps.Size(30, 30),
		origin: new google.maps.Point(0,0),
		anchor: new google.maps.Point(0, 0)
	};

	$.each($location, function (index, value) {
		let lat = +$(this).attr('data-lat');
		let lng = +$(this).attr('data-lng');

		let $map = $(this).find('.location__map')[0];

		let LatLng = { lat: lat, lng: lng };

		initMap($map, LatLng);
	});

	function initMap(element, LatLng) {
		map = new google.maps.Map(element, {
			center: LatLng,
			zoom: 16
		});

		let marker = new google.maps.Marker({
			map: map,
			position: LatLng,
			icon: icon
		});
	}

});





// function initMap() {
// 	const $map = $('#map');
//
// 	if ( !$map.length ) return false;
//
// 	let myLatLng = {lat: 29.267566, lng: 47.992850};
//
// 	let map = new google.maps.Map(document.getElementById('map'), {
// 		center: myLatLng,
// 		zoom: 16
// 	});
//
// 	let icon = {
// 		url: '../../images/icons/marker.png',
// 		scaledSize: new google.maps.Size(75, 75),
// 		origin: new google.maps.Point(0,0),
// 		anchor: new google.maps.Point(0, 0)
// 	};
//
// 	let marker = new google.maps.Marker({
// 		map: map,
// 		position: myLatLng,
// 		icon: icon
// 	});
// }