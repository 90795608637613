$(function ($) {

	const $currency = $('.currency');

	if ( !$currency.length ) return false;

	const $btn = $currency.find('.currency__btn');
	const $box = $currency.find('.currency__box');

	$btn.on('click', function () {
		$(this).toggleClass('currency__btn_active');
		$box.fadeToggle(200);
	});

});