$(function ($) {
	
	const $dropdown = $('.dropdown');
	
	if ( !$dropdown.length ) return false;

	const $btn = $dropdown.find('.dropdown__btn');
	const $box = $dropdown.find('.dropdown__box');

	$btn.on('click', function (event) {
		event.stopPropagation();

		if ( !$(this).hasClass('dropdown__btn_active') ) {
			closeAll();
		}

		$(this).toggleClass('dropdown__btn_active');
		$(this).parent().find('.dropdown__box').fadeToggle(200);
	});

	$box.on('click', function (event) {
		event.stopPropagation();
	});

	$(document).on('click', function () {
		closeAll();
	});

	function closeAll() {
		$btn.removeClass('dropdown__btn_active');
		$box.fadeOut(200);
	}
	
});