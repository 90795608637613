$(function ($) {

	const $bookingmask = $('.bookingmask');

	if ( !$bookingmask.length ) return false;

	const $booking = $('.booking');

	$bookingmask.on('click', function () {
		$(this).toggleClass('bookingmask_active');
		$booking.slideToggle(200);
	});

});