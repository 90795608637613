$(function ($) {

	$('body').append('<span class="js-get-resolution"></span>');

});
const app = {

	breakpoints: {
		sm: 576,
		md: 768,
		lg: 992,
		xl: 1200
	},

	resolutionsMap: {
		'1': 'xs',
		'2': 'sm',
		'3': 'md',
		'4': 'lg',
		'5': 'xl'
	},

	getResolution: function () {
		let param = parseInt( $('.js-get-resolution').css('z-index') );
		let resolution = {
			number: param
		};

		switch ( param ) {
			case 1: resolution.name = 'xs'; break;
			case 2: resolution.name = 'sm'; break;
			case 3: resolution.name = 'md'; break;
			case 4: resolution.name = 'lg'; break;
			case 5: resolution.name = 'xl'; break;
		}

		return resolution;
	},

	hardTrim: function (string) {
		return string.trim().replace(/\s\s+/g, ' ');
	}

};

$(function ($) {

	let $fancybox = $('[data-fancybox]');

	if ( !$fancybox.length ) return false;

	$fancybox.fancybox({
		buttons: [
			// "zoom",
			// "share",
			// "slideShow",
			// "fullScreen",
			// "download",
			// "thumbs",
			"close"
		]
	});

});
$(function ($) {

	let $srcset = $('img[data-srcset], [data-bg-srcset]');

	if ( $srcset.length ) {
		let timer;

		set();

		$(window).on('resize', function () {
			if ( timer ) clearTimeout(timer);
			timer = setTimeout(set, 50);
		});

		function set() {

			$.each($srcset, function (index, value) {
				let $this = $(this);
				let paths = {};
				let resolution = app.getResolution();
				let data = $this.data('srcset') || $this.data('bg-srcset');
				let flag = $this.data('srcset') ? 'image' : 'background';
				let srcset = data
					.split(',')
					.map(function (elem) {
						return app.hardTrim(elem).split(' ');
					});

				$.each(srcset, function (index, value) {
					paths[value[1]] = value[0];
				});

				while ( resolution.number ) {
					let resolutionName = app['resolutionsMap'][resolution.number.toString()];
					if ( resolutionName in paths ) {
						if ( flag === 'image' ) {
							$this.attr('src', paths[resolutionName]);
						}
						else {
							$this.css('background-image', `url(${paths[resolutionName]})`);
						}
						break;
					}
					resolution.number--;
				}
			});
		}
	}

});