$(function ($) {

	const $header = $('.header');

	if ( !$header.length ) return false;

	const $navBtn = $header.find('.header__nav-btn');
	const $nav = $header.find('.header__nav');

	$navBtn.on('click', function (event) {
		$(this).toggleClass('nav-btn_active');
		$nav.slideToggle(200);
	});

	$(document).on('click', function (event) {
		const $target = $(event.target);

		if (
			!$target.is('.header__nav') &&
			!$target.parents().is('.header__nav') &&
			!$target.is('.header__nav-btn') &&
			!$target.parents().is('.header__nav-btn')
		) {
			$navBtn.removeClass('nav-btn_active');
			$nav.slideUp(200);
		}
	});

});